import React from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import ProductListItem from "../components/ProductListItem";
import SEO from "../components/seo";
import homeStyles from "../styles/home.module.css";
import "../styles/home.css";
import ArrowDecor from "../components/home/ArrowDecor";
import Header from "../components/Header";
import Footer from "../components/Footer";

const HomePageTemplate = ({ data, pageContext, location }) => {
  const page = data.markdownRemark.frontmatter;
  const siteTitle = data.site.siteMetadata.title;
  const siteUrl = data.site.siteMetadata.siteUrl;
  const { previous, next } = pageContext;

  const products = data.allMarkdownRemark.edges;
  console.log("productz", products, page);

  // const products = page.products
  // console.log("products", products)

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet
        bodyAttributes={{
          class: "site-layout--landing bg-image bg-fixed",
        }}
      />
      {/* <Header /> */}
      <body class="stretched" data-loader-html="<div><img src='demos/photographer/images/icons/loader.svg' alt='Loader'/></div>">


<div id="wrapper" class="clearfix">

<div id="preloader">
		<div class="loader_line"></div>
	</div>


  <div class="tokyo_tm_all_wrap" data-magic-cursor="" data-color="black">
	<div class="tokyo_tm_modalbox">
		<div class="box_inner">
			<div class="close">
				<a href="#"><img class="svg" src="/assets/img/svg/cancel.svg" alt="" /></a>
			</div>
			<div class="description_wrap"></div>
		</div>
	</div>

	<div class="tokyo_tm_modalbox_about">
		<div class="box_inner">
			<div class="close">
				<a href="#"><img class="svg" src="/assets/img/svg/cancel.svg" alt="" /></a>
			</div>
			<div class="description_wrap">
				<div class="my_box">
					<div class="left">
						<div class="about_title">
							<h3>Photography Skills</h3>
						</div>
						<div class="tokyo_progress">
							<div class="progress_inner" data-value="95">
								<span><span class="label">Wedding Photography</span><span class="number">95%</span></span>
								<div class="background"><div class="bar"><div class="bar_in"></div></div></div>
							</div>
							<div class="progress_inner" data-value="80" >
								<span><span class="label">Lifestyle Photography</span><span class="number">80%</span></span>
								<div class="background"><div class="bar"><div class="bar_in"></div></div></div>
							</div>
							<div class="progress_inner" data-value="90">
								<span><span class="label">Family Photography</span><span class="number">90%</span></span>
								<div class="background"><div class="bar"><div class="bar_in"></div></div></div>
							</div>
						</div>
					</div>
					<div class="right">
						<div class="about_title">
							<h3>Language Skills</h3>
						</div>
						<div class="tokyo_progress">
							<div class="progress_inner" data-value="95">
								<span><span class="label">English</span><span class="number">95%</span></span>
								<div class="background"><div class="bar"><div class="bar_in"></div></div></div>
							</div>
							<div class="progress_inner" data-value="90">
								<span><span class="label">Japanese</span><span class="number">90%</span></span>
								<div class="background"><div class="bar"><div class="bar_in"></div></div></div>
							</div>
							<div class="progress_inner" data-value="85">
								<span><span class="label">Arabian</span><span class="number">85%</span></span>
								<div class="background"><div class="bar"><div class="bar_in"></div></div></div>
							</div>
						</div>
					</div>
				</div>
				<div class="counter">
					<div class="about_title">
						<h3>Fun Facts</h3>
					</div>
					<ul>
						<li>
							<div class="list_inner">
								<h3><span class="tokyo_tm_counter" data-from="0" data-to="777" data-speed="1500">0</span></h3>
								<span class="name">Projects Completed</span>
							</div>
						</li>
						<li>
							<div class="list_inner">
								<h3><span class="tokyo_tm_counter" data-from="0" data-to="500" data-speed="1500">0</span>+</h3>
								<span class="name">Happy Clients</span>
							</div>
						</li>
						<li>
							<div class="list_inner">
								<h3><span class="tokyo_tm_counter" data-from="0" data-to="100" data-speed="1500">0</span>K+</h3>
								<span class="name">Lines of Code</span>
							</div>
						</li>
					</ul>
				</div>
				<div class="partners">
					<div class="about_title">
						<h3>Our Partners</h3>
					</div>
					<ul class="owl-carousel">
						<li class="item"><a href="#"><img src="/assets/img/partners/1.png" alt="" /></a></li>
						<li class="item"><a href="#"><img src="/assets/img/partners/2.png" alt="" /></a></li>
						<li class="item"><a href="#"><img src="/assets/img/partners/3.png" alt="" /></a></li>
						<li class="item"><a href="#"><img src="/assets/img/partners/4.png" alt="" /></a></li>
						<li class="item"><a href="#"><img src="/assets/img/partners/5.png" alt="" /></a></li>
						<li class="item"><a href="#"><img src="/assets/img/partners/6.png" alt="" /></a></li>
						<li class="item"><a href="#"><img src="/assets/img/partners/7.png" alt="" /></a></li>
						<li class="item"><a href="#"><img src="/assets/img/partners/8.png" alt="" /></a></li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<div class="tokyo_tm_mobile_menu">
		<div class="menu_inner">
			<div class="logo">
				<img src="/assets/img/logo/wolfstrom-nobg.png" alt="" />
			</div>
			<div class="menu">
				<ul>
					<li><a href="#home"><img class="svg" src="/assets/img/svg/home-run.svg" alt="" /></a></li>
					{/* <li><a href="#about"><img class="svg" src="/assets/img/svg/avatar.svg" alt="" /></a></li> */}
					<li><a href="#portfolio"><img class="svg" src="/assets/img/svg/briefcase.svg" alt="" /></a></li>
					<li><a href="#products"><img class="svg" src="/assets/img/svg/paper.svg" alt="" /></a></li>
					<li><a href="#contact"><img class="svg" src="/assets/img/svg/mail.svg" alt="" /></a></li>
				</ul>
			</div>
		</div>
	</div>

	<div class="leftpart">
		<div class="leftpart_inner">
			<div class="logo">
				<a href="#"><img src="/assets/img/logo/wolfstrom-nobg.png" alt="" /></a>
			</div>
			<div class="menu">
				<ul>
					<li class="active"><a href="#home">Home</a></li>
					{/* <li><a href="#about">About</a></li> */}
					<li><a href="#portfolio">Gallery</a></li>
					<li><a href="#products">Products</a></li>
					<li><a href="#contact">Contact</a></li>
				</ul>
			</div>
			<div class="copyright">
				<p>&copy; 2021 Wolfstrom<br/>Created by <a href="https://themeforest.net/item/tokyo-personal-portfolio-template/31647361" target="_blank">European Music Consortium</a></p>
			</div>
		</div>
	</div>

	<div class="rightpart">
		<div class="rightpart_in">

			<div id="home" class="tokyo_tm_section active">
				<div class="container">
					<div class="tokyo_tm_home">
						<div class="home_content">
							<div class="avatar">
								<div class="image" data-img-url={page.landingImage}></div>
							</div>
							<div class="details">
								{/* <h3 class="name">WOLFSTROM <span></span></h3> */}
                <img src="/assets/img/logo/wolfstrom-nobg.png" className="home-logo" alt="" />
                <span style={{display: "block"}}>Detected: <img class="svg location-icon" src="/assets/img/icon/location.png" alt="" /><strong className="mr-2">Indonesia</strong></span><small>(Region Distributor: Apex Warrior)</small>
								<p class="job">For many years, Wolfstrom has been dedicated to making the finest German engineered affordable digital pianos in the world. Our technology are employed today to ensure our <strong>uncompromising standards of quality</strong>, in turn ensuring that the Wolfstrom remains the <strong>peerless instrument of uncompromising expression.</strong></p>

                <audio controls className="mb-1">
                  <source src="/assets/audio/chopin.mp3" type="audio/mpeg"/>
                Your browser does not support the audio element.
                </audio>
                <div class="social">
									<ul>
										{/* <li><a href="#"><img class="svg" src="/assets/img/svg/social/facebook.svg" alt="" /></a></li>
										<li><a href="#"><img class="svg" src="/assets/img/svg/social/twitter.svg" alt="" /></a></li> */}
										<li><a href={page.instaStoreUrl}><img height="30" class="svg instagram-icon" src="/assets/canvas/demos/photographer/images/icons/instagram.svg" alt="" /></a></li>
										<li><a href={page.shopeeUrl} target="_blank" class="my-0 mr-0 ml-0 social-icon si-small si-borderless shopee-link">
                    <img height="30" src="/azzet/img/payment/shopeeremovedwhitebg.png"/>
                  </a></li>
										<li><a href={page.tokopediaUrl} target="_blank" class="my-0 mr-0 ml-0 social-icon si-small si-borderless tokped-link">
                    <img height="30" src="/azzet/img/payment/tokpedremovedwhitebg.png"/>
                  </a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="about" class="tokyo_tm_section">
				<div class="container">
					<div class="tokyo_tm_about">
						<div class="about_image">
							<img src="/assets/img/thumbs/4-2.jpg" alt="" />
							<div class="main" data-img-url="/assets/img/portfolio/piano-4.jpeg"></div>
						</div>
						<div class="description">
							<h3 class="name">Adriano Smith &amp; <span>Photographer</span></h3>
							<div class="description_inner">
								<div class="left">
									<p>Hello, I am a creative photographer based in New York and happy to travel all over Europe to capture your big day in candid and authentic photos. I will create a lasting memory of the people.</p>
									<div class="tokyo_tm_button">
										<a href="#">Learn More</a>
									</div>
								</div>
								<div class="right">
									<ul>
										<li><p><span>Birthday:</span>01.07.1990</p></li>
										<li><p><span>Age:</span>31</p></li>
										<li><p><span>Address:</span>Ave 11, New York, USA</p></li>
										<li><p><span>Email:</span><a href="mailto:mail@gmail.com">mail@gmail.com</a></p></li>
										<li><p><span>Phone:</span><a href="tel:+770221770505">+77 022 177 05 05</a></p></li>
										<li><p><span>Study:</span>Univercity of Texas</p></li>
										<li><p><span>Freelance:</span>Available</p></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="tokyo_tm_portfolio_titles"></div>
			<div id="portfolio" class="tokyo_tm_section">
				<div class="container">
					<div class="tokyo_tm_portfolio">
						<div class="tokyo_tm_title">
							<div class="title_flex">
								<div class="left">
									<span>Gallery</span>
									<h3>Product Gallery</h3>
								</div>
								<div class="portfolio_filter">
									<ul>
										<li><a href="#" class="current" data-filter="*">All</a></li>
										{/* <li><a href="#" data-filter=".vimeo">Vimeo</a></li> */}
										<li><a href="#" data-filter=".youtube">Videos</a></li>
										{/* <li><a href="#" data-filter=".soundcloud">Soundcloud</a></li> */}
										<li><a href="#" data-filter=".image">Images</a></li>
										{/* <li><a href="#" data-filter=".detail">Detail</a></li> */}
									</ul>
								</div>
							</div>
						</div>
						<div class="list_wrapper">
							<ul class="portfolio_list gallery_zoom">
								{/* <li class="vimeo">
									<div class="inner">
										<div class="entry tokyo_tm_portfolio_animation_wrap" data-title="Teresa Butler" data-category="Vimeo">
											<a class="popup-vimeo" href="https://vimeo.com/312334044">
												<img src="/assets/img/thumbs/1-1.jpg" alt="" />
												<div class="main_image" data-img-url="/assets/img/portfolio/5.jpg"></div>
											</a>
										</div>
									</div>
								</li> */}
                {page.galleryvideos.map(vid => {
                  return (
                    <li class="youtube">
                      <div class="inner">
                        <div class="entry tokyo_tm_portfolio_animation_wrap" data-title={vid.title} data-category="Youtube">
                          <a class="popup-youtube" href={vid.url}>
                            <img src="/assets/img/thumbs/1-1.jpg" alt="" />
                            <div class="main_image" data-img-url={vid.image}></div>
                          </a>
                        </div>
                      </div>
                    </li>
                  )
                })}
								{/* <li class="soundcloud">
									<div class="inner">
										<div class="entry tokyo_tm_portfolio_animation_wrap" data-title="Derek Smith" data-category="Soundcloud">
											<a class="soundcloude_link mfp-iframe audio" href="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/252739311&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
												<img src="/assets/img/thumbs/1-1.jpg" alt="" />
												<div class="main_image" data-img-url="/assets/img/portfolio/4.jpg"></div>
											</a>
										</div>
									</div>
								</li> */}
                {page.galleryimages.map(img => {
                  return (
                    <li class="image">
                      <div class="inner">
                        <div class="entry tokyo_tm_portfolio_animation_wrap" data-title={img.title} data-category="Image">
                          <a class="zoom" href={img.image}>
                            <img src="/assets/img/thumbs/1-1.jpg" alt="" />
                            <div class="main_image" data-img-url={img.image}></div>
                          </a>
                        </div>
                      </div>
                    </li>
                  )
                })}
								{/* <li class="image">
									<div class="inner">
										<div class="entry tokyo_tm_portfolio_animation_wrap" data-title="Gloria Jenkins" data-category="Image">
											<a class="zoom" href="/assets/img/portfolio/3.jpg">
												<img src="/assets/img/thumbs/1-1.jpg" alt="" />
												<div class="main_image" data-img-url="/assets/img/portfolio/3.jpg"></div>
											</a>
										</div>
									</div>
								</li> */}
								{/* <li class="detail">
									<div class="inner">
										<div class="entry tokyo_tm_portfolio_animation_wrap" data-title="Selena Gomez" data-category="Detail">
											<a class="popup_info" href="#">
												<img src="/assets/img/thumbs/1-1.jpg" alt="" />
												<div class="main_image" data-img-url="/assets/img/portfolio/7.jpg"></div>
											</a>
										</div>
									</div>
									<div class="details_all_wrap">
										<div class="popup_details">
											<div class="top_image"></div>
											<div class="portfolio_main_title"></div>
											<div class="main_details">
												<div class="textbox">
													<p>We live in a world where we need to move quickly and iterate on our ideas as flexibly as possible. Building mockups strikes the ideal balance between true-life representation of the end product and ease of modification.</p>
													<p>Mockups are useful both for the creative phase of the project - for instance when you're trying to figure out your user flows or the proper visual hierarchy - and the production phase when they will represent the target product. Making mockups a part of your creative and development process allows you to quickly and easily ideate.</p>
												</div>
												<div class="detailbox">
													<ul>
														<li>
															<span class="first">Client</span>
															<span>Alvaro Morata</span>
														</li>
														<li>
															<span class="first">Category</span>
															<span><a href="#">Detail</a></span>
														</li>
														<li>
															<span class="first">Date</span>
															<span>March 07, 2021</span>
														</li>
														<li>
															<span class="first">Share</span>
															<ul class="share">
																<li><a href="#"><img class="svg" src="/assets/img/svg/social/facebook.svg" alt="" /></a></li>
																<li><a href="#"><img class="svg" src="/assets/img/svg/social/twitter.svg" alt="" /></a></li>
																<li><a href="#"><img class="svg" src="/assets/img/svg/social/instagram.svg" alt="" /></a></li>
																<li><a href="#"><img class="svg" src="/assets/img/svg/social/dribbble.svg" alt="" /></a></li>
																<li><a href="#"><img class="svg" src="/assets/img/svg/social/tik-tok.svg" alt="" /></a></li>
															</ul>
														</li>
													</ul>
												</div>
											</div>
											<div class="additional_images">
												<ul>
													<li>
														<div class="list_inner">
															<div class="my_image">
																<img src="/assets/img/thumbs/4-2.jpg" alt="" />
																<div class="main" data-img-url="/assets/img/portfolio/1.jpg"></div>
															</div>
														</div>
													</li>
													<li>
														<div class="list_inner">
															<div class="my_image">
																<img src="/assets/img/thumbs/4-2.jpg" alt="" />
																<div class="main" data-img-url="/assets/img/portfolio/2.jpg"></div>
															</div>
														</div>
													</li>
													<li>
														<div class="list_inner">
															<div class="my_image">
																<img src="/assets/img/thumbs/4-2.jpg" alt="" />
																<div class="main" data-img-url="/assets/img/portfolio/3.jpg"></div>
															</div>
														</div>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</li> */}
								{/* <li class="detail">
									<div class="inner">
										<div class="entry tokyo_tm_portfolio_animation_wrap" data-title="Ave Simone" data-category="Detail">
											<a class="popup_info" href="#">
												<img src="/assets/img/thumbs/1-1.jpg" alt="" />
												<div class="main_image" data-img-url="/assets/img/portfolio/8.jpg"></div>
											</a>
										</div>
									</div>
									<div class="details_all_wrap">
										<div class="popup_details">
											<div class="top_image"></div>
											<div class="portfolio_main_title"></div>
											<div class="main_details">
												<div class="textbox">
													<p>We live in a world where we need to move quickly and iterate on our ideas as flexibly as possible. Building mockups strikes the ideal balance between true-life representation of the end product and ease of modification.</p>
													<p>Mockups are useful both for the creative phase of the project - for instance when you're trying to figure out your user flows or the proper visual hierarchy - and the production phase when they will represent the target product. Making mockups a part of your creative and development process allows you to quickly and easily ideate.</p>
												</div>
												<div class="detailbox">
													<ul>
														<li>
															<span class="first">Client</span>
															<span>Alvaro Morata</span>
														</li>
														<li>
															<span class="first">Category</span>
															<span><a href="#">Detail</a></span>
														</li>
														<li>
															<span class="first">Date</span>
															<span>March 07, 2021</span>
														</li>
														<li>
															<span class="first">Share</span>
															<ul class="share">
																<li><a href="#"><img class="svg" src="/assets/img/svg/social/facebook.svg" alt="" /></a></li>
																<li><a href="#"><img class="svg" src="/assets/img/svg/social/twitter.svg" alt="" /></a></li>
																<li><a href="#"><img class="svg" src="/assets/img/svg/social/instagram.svg" alt="" /></a></li>
																<li><a href="#"><img class="svg" src="/assets/img/svg/social/dribbble.svg" alt="" /></a></li>
																<li><a href="#"><img class="svg" src="/assets/img/svg/social/tik-tok.svg" alt="" /></a></li>
															</ul>
														</li>
													</ul>
												</div>
											</div>
											<div class="additional_images">
												<ul>
													<li>
														<div class="list_inner">
															<div class="my_image">
																<img src="/assets/img/thumbs/4-2.jpg" alt="" />
																<div class="main" data-img-url="/assets/img/portfolio/1.jpg"></div>
															</div>
														</div>
													</li>
													<li>
														<div class="list_inner">
															<div class="my_image">
																<img src="/assets/img/thumbs/4-2.jpg" alt="" />
																<div class="main" data-img-url="/assets/img/portfolio/2.jpg"></div>
															</div>
														</div>
													</li>
													<li>
														<div class="list_inner">
															<div class="my_image">
																<img src="/assets/img/thumbs/4-2.jpg" alt="" />
																<div class="main" data-img-url="/assets/img/portfolio/3.jpg"></div>
															</div>
														</div>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</li> */}
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div id="products" class="tokyo_tm_section">
				<div class="container">
					<div class="tokyo_tm_news">
						<div class="tokyo_tm_title">
							<div class="title_flex">
								<div class="left">
									<span>Products</span>
									<h3>Our Collection</h3>
								</div>
							</div>
						</div>
						<ul>
              {products.map(p => {
                let product = p.node.frontmatter
                return (
                  <li>
                    <div class="list_inner">
                      <div class="image">
                        <img src="/assets/img/thumbs/4-3.jpg" alt="" />
                        <div class="main" data-img-url={product.thumbnail}></div>
                        <a class="tokyo_tm_full_link" href="#"></a>
                      </div>
                      <div class="details">
                        <div class="extra">
                          <div class="short">
                            <p class="date">{new Intl.NumberFormat('id', { style: 'currency', currency: 'IDR' }).format(product.price)}</p>
                          </div>
                          <div class="my_like">
                            <a href="#"><img class="svg" src="/assets/img/svg/like.svg" alt="" /><span>{`${Math.floor(Math.random() * (30 - 10) + 10)/10.0}k`}</span></a>
                          </div>
                        </div>
                        <h3 class="title"><a href="#">{product.name}</a></h3>
                        <div class="tokyo_tm_read_more">
                          <a href="#"><span>Read More</span></a>
                        </div>
                      </div>
                      <div class="main_content">
                        <div class="quotebox">
                          <div class="icon">
                            <img class="svg" src="/assets/img/svg/quote.svg" alt="" />
                          </div>
                          <p>{product.description}</p>
                        </div>
                        <div
                          className="descriptions ml-3"
                          dangerouslySetInnerHTML={{ __html: p.node.html }}
                        />

                        {/* <div class="news_share">
                          <span>Share:</span>
                          <ul>
                            <li><a href="#"><img class="svg" src="/assets/img/svg/social/facebook.svg" alt="" /></a></li>
                            <li><a href="#"><img class="svg" src="/assets/img/svg/social/twitter.svg" alt="" /></a></li>
                            <li><a href="#"><img class="svg" src="/assets/img/svg/social/instagram.svg" alt="" /></a></li>
                            <li><a href="#"><img class="svg" src="/assets/img/svg/social/dribbble.svg" alt="" /></a></li>
                            <li><a href="#"><img class="svg" src="/assets/img/svg/social/tik-tok.svg"  alt="" /></a></li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  </li>
                )
              })}

						</ul>
					</div>
				</div>
			</div>

			<div id="contact" class="tokyo_tm_section">
				<div class="container">
					<div class="tokyo_tm_contact tokyo_tm_about">
						<div class="tokyo_tm_title">
							<div class="title_flex">
								<div class="left">
									<span>Contact</span>
									<h3>Get in Touch</h3>
                  <p>With our partner in Indonesia</p>
								</div>
							</div>
						</div>
						<div class="map_wrap">
							<div class="map" id="ieatmaps"></div>
						</div>
						<div class="fields description">
							{/* <form action="/" method="post" class="contact_form" id="contact_form" autocomplete="off">
								<div class="returnmessage" data-success="Your message has been received, We will contact you soon."></div>
								<div class="empty_notice"><span>Please Fill Required Fields</span></div>
								<div class="first">
									<ul>
										<li>
											<input id="name" type="text" placeholder="Name"/>
										</li>
										<li>
											<input id="email" type="text" placeholder="Email"/>
										</li>
									</ul>
								</div>
								<div class="last">
									<textarea id="message" placeholder="Message"></textarea>
								</div>
								<div class="tokyo_tm_button" data-position="left">
									<a id="send_message" href="#">
										<span>Send Message</span>
									</a>
								</div>


							</form> */}
              <div class="description_inner">
								<div class="left">
									<p>Our partner in Indonesia is Apex Warrior, headquartered in Jakarta, will be able to answer all of your questions regarding our products and provide the after-sales services. For retail or wholesale needs, please contact them with the information provided on this page.</p>
									<div class="tokyo_tm_abutton">
										<a href="https://wa.me/62895363166399?text=Hello%2C%20saya%20ingin%20informasi%20lebih%20lanjut%20tentang%20Wolfstrom"><i class="icon-whatsapp mr-1"></i>Whatsapp</a>
									</div>
								</div>
								<div class="right">
									<ul>
										<li><p><span>Address:</span>Pulogadung Trade Center</p></li>
										<li><p><span>Email:</span><a href="mailto:apexwarrior.id@gmail.com">apexwarrior.id@gmail.com</a></p></li>
										<li><p><span>Phone:</span><a href="tel:+62895363166399">+628 9536 3166 399</a></p></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>

	<div class="mouse-cursor cursor-outer"></div>
    <div class="mouse-cursor cursor-inner"></div>
    </div>
</div>


</body>

    </Layout>
  );
};

export default HomePageTemplate;

const InstaFeed = () => {
  return (
    <div id="instagramFeed" className="full-width">
      <div class="instagram_gallery">
        <a
          href="https://www.instagram.com/p/Bh-P3IoDxyB"
          rel="noopener"
          target="_blank"
        >
          <img src="..." alt="instagram instagram image 0" />
        </a>
        ...
      </div>
    </div>
  );
};

export const pageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(frontmatter: { id: { eq: "home" } }) {
      frontmatter {
        templateKey
        landingImage
        instaStoreUrl
				tokopediaUrl
				shopeeUrl
        companyAddress
        messageParagraph {
          messageBody
          messageHeader
        }
        testimonials {
          image
          text
          name
        }
        galleryvideos {
          title
          url
          image
        }
        galleryimages {
          title
          image
        }
        products {
          image
          productUrl
          name
          description
        }
        id
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "product" } }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            name
            price
            description
            date(locale: "id")
            thumbnail
            images {
              image
            }
            tag
            redLabel
            blackLabel
            tokpedUrl
            instaUrl
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
